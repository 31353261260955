<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">CONTEXTOS REGISTRADOS EN EL SISTEMA:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class=" ico fas fa-list fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaContexto.length}}</span>
                            <br>
                            <span class="text-muted">Contextos registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de contexto</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" v-if="checkPermissions('003-RIE-CON','c') == 1" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarContexto = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Contexto</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6"  v-if="checkPermissions('003-RIE-CON-PAR','r') == 1"  class="my-2">
                            <b-button block size="md" variant="custom" :to="{name : 'Partes Interesadas'}">
                                <i style="inline-size: auto" class="fas fa-users fa-3x pb-1"></i><br>Partes interesadas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Contextos registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaContexto" :fields="camposContexto" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm"  v-if="checkPermissions('003-RIE-CON','u') == 1"  v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarContexto(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm"  v-if="checkPermissions('003-RIE-CON','d') == 1"  class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarContexto(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarContexto" size="lg" color="primario" :closeOnBackdrop="false">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo contexto </span>
            </h6>
            <CButtonClose @click="modalRegistrarContexto = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarContexto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="parte_interesada" rules="required" v-slot="{errors}">
                            <b-form-group label="Parte interesada:" class="mb-2">
                                <v-select :reduce="comboParteInteresada =>comboParteInteresada.idParteInteresada" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosContexto.idParteInteresada , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosContexto.idParteInteresada" :options="comboParteInteresada" @search:blur="blurParteInteresada">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Partes Interesadas'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="foda" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Foda:">
                                <b-form-select :state="getValidationState(validationContext)" size="md" value-field="idFoda" text-field="descripcion" :options="comboFoda" v-model="datosContexto.foda">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="expectativas" v-slot="validationContext">
                            <b-form-group label="Expectativas:" class="mb-2">
                                <b-form-textarea v-model="datosContexto.expectativas" :state="getValidationState(validationContext)" placeholder="Ingrese las espectativas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="requisito" v-slot="validationContext">
                            <b-form-group label="Requisito:" class="mb-2">
                                <b-form-textarea v-model="datosContexto.requisito" :state="getValidationState(validationContext)" placeholder="Ingrese el requisito" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" @input="listarIndicadoresProceso()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosContexto.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosContexto.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="indicador" rules="required" v-slot="{errors}">
                            <b-form-group label="Indicador:" class="mb-2">
                                <v-select :reduce="comboIndicador =>comboIndicador.idIndicador" label="descripcionIndicador" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosContexto.idIndicador , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosContexto.idIndicador" :options="comboIndicador" @search:blur="blurIndicador">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Indicadores'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="evaluacion_riesgo" rules="required" v-slot="{errors}">
                            <b-form-group label="Evaluación de riesgo:" class="mb-2">
                                <v-select :reduce="comboEvaluacionRiesgo =>comboEvaluacionRiesgo.idEvaluacionRiesgo" @input="listarRiesgosEvaluacion()" label="fecha" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosContexto.idEvaluacionRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosContexto.idEvaluacionRiesgo" :options="comboEvaluacionRiesgo" @search:blur="blurEvaluacionRiesgo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Evaluación de riesgos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="riesgo_oportunidad" rules="required" v-slot="{errors}">
                            <b-form-group label="Riesgo/Oportunidad:" class="mb-2">
                                <v-select :reduce="comboRiesgo =>comboRiesgo.idRiesgo" label="riesgo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosContexto.idRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosContexto.idRiesgo" :options="comboRiesgo" @search:blur="blurRiesgo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarContexto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarContexto"  size="lg" color="primario" :closeOnBackdrop="false">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar contexto </span>
            </h6>
            <CButtonClose @click="modalActualizarContexto = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarContexto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="parte_interesada" rules="required" v-slot="{errors}">
                            <b-form-group label="Parte interesada:" class="mb-2">
                                <v-select :reduce="comboParteInteresada =>comboParteInteresada.idParteInteresada" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarContexto.idParteInteresada , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarContexto.idParteInteresada" :options="comboParteInteresada" @search:blur="blurParteInteresadaModificar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Partes Interesadas'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="foda" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Foda:">
                                <b-form-select :state="getValidationState(validationContext)" size="md" value-field="idFoda" text-field="descripcion" :options="comboFoda" v-model="datosActualizarContexto.foda">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="expectativas" v-slot="validationContext">
                            <b-form-group label="Expectativas:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarContexto.expectativas" :state="getValidationState(validationContext)" placeholder="Ingrese las espectativas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="requisito" v-slot="validationContext">
                            <b-form-group label="Requisito:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarContexto.requisito" :state="getValidationState(validationContext)" placeholder="Ingrese el requisito" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="proceso" rules="required" v-slot="{errors}">
                            <b-form-group label="Proceso:" class="mb-2">
                                <v-select :reduce="comboProcesos =>comboProcesos.idProceso" @input="listarIndicadoresProceso()" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarContexto.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarContexto.idProceso" :options="comboProcesos" @search:blur="blurProcesoModificar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="indicador" rules="required" v-slot="{errors}">
                            <b-form-group label="Indicador:" class="mb-2">
                                <v-select :reduce="comboIndicador =>comboIndicador.idIndicador" label="descripcionIndicador" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarContexto.idIndicador , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarContexto.idIndicador" :options="comboIndicador" @search:blur="blurIndicadorModificar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Indicadores'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="evaluacion_riesgo" rules="required" v-slot="{errors}">
                            <b-form-group label="Evaluación de riesgo:" class="mb-2">
                                <v-select :reduce="comboEvaluacionRiesgo =>comboEvaluacionRiesgo.idEvaluacionRiesgo" @input="listarRiesgosEvaluacion()" label="fecha" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarContexto.idEvaluacionRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarContexto.idEvaluacionRiesgo" :options="comboEvaluacionRiesgo" @search:blur="blurEvaluacionRiesgoModificar">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Evaluación de riesgos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="riesgo_oportunidad" rules="required" v-slot="{errors}">
                            <b-form-group label="Riesgo/Oportunidad:" class="mb-2">
                                <v-select :reduce="comboRiesgo =>comboRiesgo.idRiesgo" label="riesgo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarContexto.idRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarContexto.idRiesgo" :options="comboRiesgo" @search:blur="blurRiesgoModificar">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarContexto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            listaContexto: [],
            camposContexto: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "parteInteresada",
                    label: "Parte Interesada",
                    class: "text-center",
                },
                {
                    key: "foda",
                    label: "FODA",
                    class: "text-center",
                },
                {
                    key: "indicador",
                    label: "Indicador",
                    class: "text-center",
                },
                {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                },
                {
                    key: "riesgo",
                    label: "Riesgo/Oportunidad",
                    class: "text-center",
                },
                {
                    key: "fechaEvaluacionRiesgo",
                    label: "Fecha de evaluación del riesgo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosContexto: {
                idParteInteresada: null,
                expectativas: '',
                requisito: '',
                foda: null,
                idProceso: null,
                idIndicador: null,
                idEvaluacionRiesgo: null,
                idRiesgo: null,
            },

            datosActualizarContexto: {
                idContexto: '',
                idParteInteresada: null,
                expectativas: '',
                requisito: '',
                foda: null,
                idProceso: null,
                idIndicador: null,
                idEvaluacionRiesgo: null,
                idRiesgo: null,
            },

            modalRegistrarContexto: false,
            modalActualizarContexto: false,

            comboParteInteresada: [],

            comboFoda: [{
                    idFoda: "1",
                    descripcion: "Debilidad"
                },
                {
                    idFoda: "2",
                    descripcion: "Amenaza"
                },
                {
                    idFoda: "3",
                    descripcion: "Fortaleza"
                }, {
                    idFoda: "4",
                    descripcion: "Oportunidad"
                }
            ],

            comboProcesos: [],

            comboIndicador: [],

            comboEvaluacionRiesgo: [],

            comboRiesgo: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        abrirModalActualizarContexto(param) {
            this.datosActualizarContexto.idContexto = param.item.idContexto;
            this.datosActualizarContexto.idParteInteresada = param.item.idParteInteresada;
            this.datosActualizarContexto.expectativas = param.item.expectativas;
            this.datosActualizarContexto.requisito = param.item.requisito;
            this.datosActualizarContexto.foda = param.item.foda;
            this.datosActualizarContexto.idProceso = param.item.idProceso;
            this.listarIndicadoresProceso();
            this.datosActualizarContexto.idIndicador = param.item.idIndicador;
            this.datosActualizarContexto.idEvaluacionRiesgo = param.item.idEvaluacionRiesgo;
            this.listarRiesgosEvaluacion();
            this.datosActualizarContexto.idRiesgo = param.item.idRiesgo;

            this.modalActualizarContexto = true
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurProcesoModificar() {
            this.computedForm2.refs.proceso.validate();
        },
        blurParteInteresada() {
            this.computedForm.refs.parte_interesada.validate();
        },
        blurParteInteresadaModificar() {
            this.computedForm2.refs.parte_interesada.validate();
        },
        blurIndicador() {
            this.computedForm.refs.indicador.validate();
        },
        blurIndicadorModificar() {
            this.computedForm2.refs.indicador.validate();
        },
        blurEvaluacionRiesgo() {
            this.computedForm.refs.evaluacion_riesgo.validate();
        },
        blurEvaluacionRiesgoModificar() {
            this.computedForm2.refs.evaluacion_riesgo.validate();
        },
        blurRiesgo() {
            this.computedForm.refs.riesgo_oportunidad.validate();
        },
        blurRiesgoModificar() {
            this.computedForm2.refs.riesgo_oportunidad.validate();
        },
        listarContexto() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-contextos", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaContexto = response.data;
                    me.totalRows = me.listaContexto.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPartesInteresadas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-partes-interesadas", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.comboParteInteresada = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarIndicadoresProceso() {
            let me = this;

            (me.datosContexto.idIndicador = null),
            (me.datosActualizarContexto.idIndicador = null),
            (me.comboIndicador = []),
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-indicadores-proceso", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        idProceso: me.datosContexto.idProceso != null ? me.datosContexto.idProceso : me.datosActualizarContexto.idProceso,

                    }
                })
                .then(function (response) {
                    me.comboIndicador = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEvaluaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluacion-riesgo", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.comboEvaluacionRiesgo = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarRiesgosEvaluacion() {
            let me = this;

            (me.datosContexto.idRiesgo = null),
            (me.datosActualizarContexto.idRiesgo = null),
            (me.comboRiesgo = []),
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-riesgos-evaluacion-contexto", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacionRiesgo: me.datosContexto.idEvaluacionRiesgo != null ? me.datosContexto.idEvaluacionRiesgo : me.datosActualizarContexto.idEvaluacionRiesgo,
                        },
                    }
                )
                .then(function (response) {
                    me.comboRiesgo = response.data;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },

        registrarContexto() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-contexto", {
                        datosContexto: me.datosContexto,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarContexto();
                    me.modalRegistrarContexto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarContexto() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-contexto", {
                        datosContexto: me.datosActualizarContexto,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarContexto();
                    me.modalActualizarContexto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        eliminarContexto(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el contexto?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-contexto", {
                            idContexto: param.item.idContexto,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarContexto();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        resetModalRegistrarContexto() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosContexto.idParteInteresada = null;
            this.datosContexto.expectativas = '';
            this.datosContexto.requisito = '';
            this.datosContexto.foda = null;
            this.datosContexto.idProceso = null;
            this.datosContexto.idIndicador = null;
            this.datosContexto.idEvaluacionRiesgo = null;
            this.datosContexto.idRiesgo = null;

        },
        resetModalActualizarContexto() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalRegistrarContexto: function (val) {
            if (val == false) {
                this.resetModalRegistrarContexto();
            }
        },
        modalActualizarContexto: function (val) {
            if (val == false) {
                this.resetModalActualizarContexto();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarContexto();
            this.listarProcesos();
            this.listarPartesInteresadas();
            this.listarEvaluaciones();
            //this.listarIndicadoresProceso();
            //this.listarRiesgosEvaluacion();
        }
    }

}
</script>
